import { FunctionComponent, ReactElement } from 'react';
import Image from 'next/image';
import styles from './styles.module.scss';
import Animation from './Animation';
import EducatorSignUpButtonLink from '../../../components/EducatorSignUpButtonLink';

type SideBySidePropTypes = {
    image?: {
        src: string;
        alt: string;
    };
    heading?: string | ReactElement;
    text?: string;
    direction?: 'ltr' | 'rtl';
    animation?: string;
    sectionClass?: string;
    animationContainerClass?: string;
    headingSize?: number;
    children?: ReactElement;
};

export const SideBySide: FunctionComponent<SideBySidePropTypes> = ({
    image,
    heading,
    text,
    direction = 'ltr',
    animation,
    sectionClass,
    animationContainerClass,
    children,
}) => {
    const baseClass = sectionClass || styles.sbs_container;
    const directionClass = direction === 'rtl' ? styles.rtl : styles.ltr;

    return (
        <div className={`${baseClass} ${directionClass}`} key={animationContainerClass}>
            {!animation ? null : (
                <Animation
                    animation={animation}
                    animationContainerClass={animationContainerClass}
                />
            )}

            {image?.src ? (
                <Image
                    src={
                        image.src ||
                        `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/animations/${animation}/fallback.svg`
                    }
                    className={styles.sbs_img}
                    alt={image.alt}
                    width={800}
                    height={800}
                />
            ) : null}

            <div className={styles.sbs_aside}>
                <h2>{heading}</h2>
                <p>{text}</p>
                {children ? (
                    children
                ) : (
                    <EducatorSignUpButtonLink preset="primary" className={styles.cta_button} />
                )}
            </div>
        </div>
    );
};

export default SideBySide;
